/* Full-page layout styling */
.roadmap-container {
    height: 75vh; /* Adjusted height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

/* Full background video */
.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    opacity: 0.5;
}

/* Flexbox for content layout */
.full-page-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;
}

/* Image container for left side */
.image-container {
    width: 50%; /* Left side */
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

/* Text container for right side */
.text-container {
    width: 50%; /* Right side */
    padding: 60px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

/* Optional: remove button focus outline */
button:focus {
    outline: none;
}

/* Button styling */
button {
    font-size: 16px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
